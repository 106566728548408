import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Chip,
  Box,
  Autocomplete,
} from "@mui/material";
import { SportsSoccer } from "@mui/icons-material";
import axiosInstance from "../../../axiosInstance";
import moment from "moment";

const AdminReservationModal = ({ open, onClose }) => {
  const [owners, setOwners] = useState([]);
  const [courts, setCourts] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [loadingOwners, setLoadingOwners] = useState(false);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCourt, setSelectedCourt] = useState("");
  const [searchText, setSearchText] = useState("Seleccionar propietario");
  const [date, setDate] = useState(moment());

  // Cargar propietarios y canchas solo una vez
  useEffect(() => {
    const fetchInitialData = async () => {
      setLoadingOwners(true);
      try {
        const ownersResponse = await axiosInstance.get("/auth/getAllPropietarios");
        setOwners(ownersResponse.data);

        const courtsResponse = await axiosInstance.get("/courts");
        setCourts(courtsResponse.data);
      } catch (error) {
        setError(`Error cargando propietarios o canchas: ${error}`);
      } finally {
        setLoadingOwners(false);
      }
    };

    if (open && owners.length === 0) fetchInitialData();
  }, [open, owners.length]);

  // Obtener slots disponibles cuando cambie la fecha o cancha seleccionada
  const fetchSlots = useCallback(async () => {
    if (!selectedCourt) return;

    setLoadingSlots(true);
    try {
      const slotsResponse = await axiosInstance.get("/reservations/available", {
        params: { date: date.format("YYYY-MM-DD"), courtId: selectedCourt },
      });
      setAvailableSlots(slotsResponse.data);
    } catch (error) {
      setError(`Error cargando horarios disponibles: ${error}`);
    } finally {
      setLoadingSlots(false);
    }
  }, [date, selectedCourt]);

  useEffect(() => {
    if (open) fetchSlots();
  }, [fetchSlots, open]);

  const handleSlotSelection = (slot) => {
    if (!slot.available) {
      setError("Este horario no está disponible");
      return;
    }

    setSelectedSlot({
      start: moment(`${date.format("YYYY-MM-DD")}T${slot.start}`),
      end: moment(`${date.format("YYYY-MM-DD")}T${slot.end}`),
    });
  };

  const handleReservation = async () => {
    try {
      if (!selectedSlot || !selectedOwner || !selectedCourt) {
        setError("Selección inválida");
        return;
      }

      const reservationData = {
        courtId: selectedCourt,
        date: date.format("YYYY-MM-DD"),
        startTime: selectedSlot.start.format("HH:mm:ss"),
        endTime: selectedSlot.end.format("HH:mm:ss"),
        userId: selectedOwner.id,
      };

      await axiosInstance.post("/reservations/admin/reserva", reservationData);
      onClose();
      setError(null);
    } catch (error) {
      setError(error.response?.data?.error || "Error al crear reserva");
    }
  };

  const filteredOwners = owners.filter(
    (owner) =>
      owner.username.toLowerCase().includes(searchText.toLowerCase()) ||
      owner.email.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Reservar Cancha</DialogTitle>
      <DialogContent>
        {loadingOwners ? (
          <CircularProgress sx={{ display: "block", margin: "2rem auto" }} />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" gutterBottom>
                Buscar propietario
              </Typography>
              <Autocomplete
                value={selectedOwner}
                onChange={(event, newValue) => setSelectedOwner(newValue)}
                inputValue={searchText}
                onInputChange={(event, newInputValue) => setSearchText(newInputValue)}
                options={filteredOwners}
                getOptionLabel={(owner) => `${owner.username}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    placeholder="Escribe para buscar..."
                  />
                )}
                noOptionsText="No se encontraron propietarios"
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" gutterBottom>
                Seleccionar cancha
              </Typography>
              <Select
                value={selectedCourt}
                onChange={(e) => setSelectedCourt(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ minWidth: 250 }}
              >
                {courts.map((court) => (
                  <MenuItem key={court.id} value={court.id}>
                    <SportsSoccer sx={{ mr: 1, color: "text.secondary" }} />
                    {court.name} ({court.type})
                    <Chip
                      label={court.status}
                      size="small"
                      sx={{ ml: 1 }}
                      color={court.status === "available" ? "success" : "error"}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" gutterBottom>
                Seleccionar fecha
              </Typography>
              <TextField
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={date.format("YYYY-MM-DD")}
                onChange={(e) => setDate(moment(e.target.value))}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Horarios Disponibles
              </Typography>
              {loadingSlots ? (
                <CircularProgress sx={{ display: "block", margin: "1rem auto" }} />
              ) : (
                <Grid container spacing={2}>
                  {availableSlots.map((slot, index) => (
                    <Grid item xs={6} sm={4} md={3} key={index}>
                      <Chip
                        label={`${slot.start} - ${slot.end}`}
                        onClick={() => handleSlotSelection(slot)}
                        color={slot.available ? "primary" : "default"}
                        disabled={!slot.available}
                        sx={{ width: "100%", cursor: "pointer" }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleReservation}
          variant="contained"
          color="primary"
          disabled={!selectedSlot || !selectedOwner || !selectedCourt}
        >
          Confirmar Reserva
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminReservationModal;
