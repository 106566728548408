import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Box,
  Typography,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import axiosInstance from "../../axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";

const baseUrl = process.env.REACT_APP_BASE_URL;

const InvitadosTable = () => {
  const [invitados, setInvitados] = useState([]);
  const [filters, setFilters] = useState({
    loteId: "",
    nombre: "",
    ci: "",
    fechaInicio: "",
    fechaTermino: "",
  });
  const [lotes, setLotes] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedInvitado, setSelectedInvitado] = useState(null); // Para almacenar los detalles del invitado seleccionado
  const [openModal, setOpenModal] = useState(false); // Para controlar la visibilidad del modal

  useEffect(() => {
    fetchInvitados();
    fetchLotes();
  }, []);

  const fetchLotes = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/lotes`);
      setLotes(response.data);
    } catch (error) {
      console.error("Error fetching lotes:", error);
      toast.error("Error al cargar lotes");
    }
  };

  const fetchInvitados = async () => {
    try {
      const response = await axiosInstance.get("/invitados/all", { params: filters });
      setInvitados(response.data);
    } catch (error) {
      console.error("Error fetching invitados:", error);
      toast.error("Error al cargar invitados");
    }
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleSearch = () => {
    fetchInvitados();
  };

  const downloadPdf = () => {
    const doc = new jsPDF();
    const tableColumn = ["Nombre", "CI", "Género", "Email", "FML", "Fecha de Creación"];
    const tableRows = [];

    invitados.forEach((invitado) => {
      const invitadoData = [
        invitado.nombre,
        invitado.cedula_identidad,
        invitado.genero,
        invitado.email,
        invitado.Invitacions[0]
          ? `143 - ${invitado.Invitacions[0].Lote.manzana} - ${invitado.Invitacions[0].Lote.lote}`
          : "N/A",
        new Date(invitado.createdAt).toLocaleString(),
      ];
      tableRows.push(invitadoData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    doc.save("listado_invitados.pdf");
  };

  const handleSort = () => {
    const newSortDirection = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newSortDirection);
  };

  const sortedInvitados = [...invitados].sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    if (sortDirection === "asc") {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });

  const handleViewDetails = (invitado) => {
    setSelectedInvitado(invitado);
    setOpenModal(true); // Abrir el modal
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedInvitado(null); // Limpiar los datos al cerrar el modal
  };

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Typography variant="h4" gutterBottom>
        Invitados
      </Typography>
      <Box display="flex" gap={2} marginBottom={2}>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>FML</InputLabel>
          <Select name="loteId" value={filters.loteId || ""} onChange={handleFilterChange}>
            {lotes.map((lote) => (
              <MenuItem key={lote.id} value={lote.id}>
                Manzana: {lote.manzana} - Lote: {lote.lote}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField name="nombre" label="Nombre" onChange={handleFilterChange} />
        <TextField name="ci" label="CI" onChange={handleFilterChange} />
        <TextField
          name="fechaInicio"
          label="Fecha Inicio"
          type="date"
          InputLabelProps={{ shrink: true }}
          onChange={handleFilterChange}
        />
        <TextField
          name="fechaTermino"
          label="Fecha Término"
          type="date"
          InputLabelProps={{ shrink: true }}
          onChange={handleFilterChange}
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Buscar
        </Button>
        <Button variant="contained" color="secondary" onClick={downloadPdf}>
          Descargar PDF
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>CI</TableCell>
              <TableCell>Género</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>FML</TableCell>
              <TableCell>
                <Button onClick={handleSort}>
                  Fecha de Creación {sortDirection === "asc" ? "↑" : "↓"}
                </Button>
              </TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedInvitados.map((invitado) => (
              <TableRow key={invitado.id}>
                <TableCell>{invitado.nombre}</TableCell>
                <TableCell>{invitado.cedula_identidad}</TableCell>
                <TableCell>{invitado.genero}</TableCell>
                <TableCell>{invitado.email}</TableCell>
                <TableCell>
                  {invitado.Invitacions[0]
                    ? `143 - ${invitado.Invitacions[0].Lote.manzana} - ${invitado.Invitacions[0].Lote.lote}`
                    : "N/A"}
                </TableCell>
                <TableCell>{new Date(invitado.createdAt).toLocaleString()}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleViewDetails(invitado)}>
                    Ver Detalles
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal para mostrar los detalles del invitado */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              padding: 2,
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            {selectedInvitado && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Detalles del Invitado
                </Typography>
                {selectedInvitado.foto && (
                  <img
                    src={`${baseUrl}/${selectedInvitado.foto}`}
                    alt="Foto del invitado"
                    style={{
                      width: "150px", // Ajusta el tamaño aquí
                      height: "150px", // Asegurando que sea cuadrada
                      objectFit: "cover", // Para recortar la imagen si es necesario
                      borderRadius: "8px", // Bordes redondeados
                      marginBottom: 10,
                    }}
                  />
                )}
                <Typography variant="body1">
                  <strong>Nombre:</strong> {selectedInvitado.nombre}
                </Typography>
                <Typography variant="body1">
                  <strong>CI:</strong> {selectedInvitado.cedula_identidad}
                </Typography>
                <Typography variant="body1">
                  <strong>Género:</strong> {selectedInvitado.genero}
                </Typography>
                <Typography variant="body1">
                  <strong>Email:</strong> {selectedInvitado.email}
                </Typography>
                <Typography variant="body1">
                  <strong>FML:</strong>{" "}
                  {selectedInvitado.Invitacions[0]
                    ? `143 - ${selectedInvitado.Invitacions[0].Lote.manzana} - ${selectedInvitado.Invitacions[0].Lote.lote}`
                    : "N/A"}
                </Typography>

                <Typography variant="body1">
                  <strong>Fecha Inicio:</strong>
                  <br />
                  {selectedInvitado.fechaInicioUltima
                    ? new Date(selectedInvitado.fechaInicioUltima).toLocaleString()
                    : "N/A"}
                </Typography>

                <Typography variant="body1">
                  <strong>Fecha Termino:</strong>
                  <br />
                  {selectedInvitado.fechaTerminoUltima
                    ? new Date(selectedInvitado.fechaTerminoUltima).toLocaleString()
                    : "N/A"}
                </Typography>
              </Box>
            )}
            {/* Botón Cerrar alineado a la derecha */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
              <Button variant="contained" color="secondary" onClick={handleCloseModal}>
                Cerrar
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default InvitadosTable;
