import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import {
  TextField,
  Button,
  Container,
  Typography,
  Paper,
  Box,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SendIcon from "@mui/icons-material/Send";

const SendResetCode = () => {
  const [cedula, setCedula] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSendCode = async () => {
    if (!cedula.trim()) {
      toast.error("Por favor ingresa tu cédula");
      return;
    }

    try {
      setLoading(true);
      await axiosInstance.post("/auth/send-reset-code", { cedula });
      toast.success("Código enviado a tu número registrado");

      setTimeout(() => {
        navigate("/reset-password", { state: { cedula } });
      }, 1500);
    } catch (error) {
      toast.error(error.response?.data?.error || "Error enviando código");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Paper
          elevation={6}
          sx={{
            padding: 4,
            borderRadius: 3,
            textAlign: "center",
            width: "100%",
            maxWidth: 400,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", color: "#333" }}>
            Recuperar Contraseña
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, color: "#666" }}>
            Ingresa tu cédula y te enviaremos un código para restablecer tu contraseña.
          </Typography>

          <TextField
            label="Cédula de Identidad"
            variant="outlined"
            fullWidth
            value={cedula}
            onChange={(e) => setCedula(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 3 }}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSendCode}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              textTransform: "none",
              py: 1.2,
            }}
          >
            {loading ? "Enviando..." : "Enviar Código"}
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default SendResetCode;
