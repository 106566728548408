import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Chip,
  Grid,
  Box,
  Avatar,
  Divider,
  Skeleton,
} from "@mui/material";
import {
  HomeRounded,
  GroupRounded,
  ReceiptRounded,
  DescriptionRounded,
  CalendarMonthRounded,
  MailRounded,
  PersonRounded,
} from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";
import UserContext from "../../UserContext";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const baseUrl = process.env.REACT_APP_BASE_URL;

// Estilos mejorados
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: "16px",
  boxShadow: theme.shadows[4],
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[8],
  },
  position: "relative",
  overflow: "visible",
  "&:before": {
    content: '""',
    position: "absolute",
    top: -8,
    left: "50%",
    transform: "translateX(-50%)",
    width: "90%",
    height: "16px",
    borderRadius: "16px",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}));

const NavigationCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: "12px",
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
  transition: "all 0.2s ease",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.light, 0.2),
    transform: "scale(1.02)",
  },
  textAlign: "center",
  cursor: "pointer",
  minHeight: 120,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const StatusChip = styled(Chip)(({ theme, status }) => ({
  borderRadius: "8px",
  fontWeight: 600,
  backgroundColor:
    status === "habilitado"
      ? alpha(theme.palette.success.main, 0.2)
      : alpha(theme.palette.error.main, 0.2),
  color: status === "habilitado" ? theme.palette.success.dark : theme.palette.error.dark,
}));

const LotesPro = () => {
  const { user } = useContext(UserContext);
  const [lotes, setLotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentLote, setCurrentLote] = useState({
    lote: "",
    manzana: "",
    estado: "habilitado",
    userId: "",
    sharedUserIds: [],
  });

  useEffect(() => {
    fetchLotes();
  }, []);

  const fetchLotes = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/lotes/getMyLotes`);
      setLotes(response.data);
    } finally {
      setLoading(false);
    }
  };

  const navigationItems = [
    // { title: "Lotes", icon: <HomeRounded fontSize="large" />, path: "/lotesPro" },
    { title: "Invitados", icon: <GroupRounded fontSize="large" />, path: "/invitados" },
    {
      title: "Comprobantes",
      icon: <ReceiptRounded fontSize="large" />,
      path: "/propietario/comprobantes",
    },
    {
      title: "Voucher",
      icon: <DescriptionRounded fontSize="large" />,
      path: "/propietario/voucher",
    },
    {
      title: "Reservas",
      icon: <CalendarMonthRounded fontSize="large" />,
      path: "/propietario/reserva",
    },
    { title: "Contactos", icon: <MailRounded fontSize="large" />, path: "/contactos" },
    { title: "Inquilinos", icon: <PersonRounded fontSize="large" />, path: "/inquilinos" },
  ];

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Box sx={{ mb: 4, textAlign: "center" }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 800,
            mb: 2,
            background: "linear-gradient(45deg, #2d7ee9 30%, #1ac8db 90%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Gestión de Propiedades
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Accede a las diferentes secciones de tu propiedad
        </Typography>
      </Box>

      {/* Sección de navegación */}
      <Grid container spacing={3} sx={{ mb: 6 }}>
        {navigationItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.path}>
            <motion.div whileHover={{ scale: 1.03 }}>
              <NavigationCard
                component={Link}
                to={item.path}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                <Box sx={{ color: "primary.main", mb: 1 }}>{item.icon}</Box>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {item.title}
                </Typography>
              </NavigationCard>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      {/* Sección de Lotes */}
      <Box sx={{ mb: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            Mis Lotes Registrados
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {loading ? (
            Array.from(new Array(4)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rounded" height={180} />
              </Grid>
            ))
          ) : lotes.length === 0 ? (
            <Grid item xs={12}>
              <Box
                sx={{
                  textAlign: "center",
                  p: 4,
                  borderRadius: 4,
                  backgroundColor: "action.hover",
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  No tienes lotes registrados
                </Typography>
              </Box>
            </Grid>
          ) : (
            lotes.map((lote) => (
              <Grid item xs={12} sm={6} md={4} key={lote.id}>
                <motion.div whileHover={{ scale: 1.02 }}>
                  <StyledCard>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: "primary.main",
                            mr: 2,
                            width: 40,
                            height: 40,
                          }}
                        >
                          <HomeRounded />
                        </Avatar>
                        <Box>
                          <Typography variant="h6" sx={{ fontWeight: 700 }}>
                            Lote {lote.lote}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Manzana {lote.manzana}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box>
                          <Typography variant="caption" color="text.secondary">
                            Estado
                          </Typography>
                          <StatusChip
                            status={lote.estado}
                            label={lote.estado.toUpperCase()}
                            sx={{ ml: 1 }}
                          />
                        </Box>
                        <Box>
                          <Typography variant="caption" color="text.secondary">
                            Propietario
                          </Typography>
                          <Chip
                            label={lote.Users[0]?.username || "-"}
                            variant="outlined"
                            sx={{ ml: 1 }}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </StyledCard>
                </motion.div>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default LotesPro;
