import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Importar los estilos de react-toastify
import theme from "../../theme";
import backgroundImage from "../../backgrund.jpeg";

function SignIn() {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginData = {
      cedula: data.get("cedula"),
      password: data.get("password"),
    };
    try {
      const response = await axios.post(`${baseUrl}/auth/login`, loginData);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userRole", JSON.stringify(response.data.user.role));
      localStorage.setItem("user", JSON.stringify(response.data.user));

      let route = "";

      if (response.data.user.role === "admin") route = "/dashboard";
      else if (response.data.user.role === "portero") route = "/propietarios";
      else if (response.data.user.role === "propietario") route = "/lotesPro";
      else if (response.data.user.role === "mantenimiento") route = "/lotesMant";
      else if (response.data.user.role === "casual") route = "/scan-voucher";

      navigate(route, { replace: true });
      window.location.reload();
    } catch (error) {
      console.error("Error during login:", error);
      toast.error(
        error.response?.data?.message || "Error al iniciar sesión, por favor intenta nuevamente."
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.85)", // Fondo blanco con transparencia
              padding: 4,
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <img src="/assets/logo-color.png" alt="Logo" style={{ height: 200 }} />
            <Typography component="h1" variant="h5">
              Iniciar Sesión
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="cedula"
                label="Cédula"
                name="cedula"
                autoComplete="cedula"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Recordarme"
              /> */}
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Iniciar Sesión
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="/send-reset-code" variant="body2">
                    {"Olvidé mi contraseña"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default SignIn;
