import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import {
  TextField,
  Button,
  Container,
  Typography,
  Paper,
  Box,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LockIcon from "@mui/icons-material/Lock";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cedula = location.state?.cedula || ""; // Recibe la cédula de la pantalla anterior

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async () => {
    if (!code.trim() || !password.trim()) {
      toast.error("Por favor completa todos los campos");
      return;
    }

    try {
      setLoading(true);
      await axiosInstance.post("/auth/verify-reset-code", { cedula, code, newPassword: password });
      toast.success("Contraseña cambiada con éxito");

      setTimeout(() => {
        navigate("/");
      }, 1500);
    } catch (error) {
      toast.error(error.response?.data?.error || "Error al cambiar contraseña");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Paper
          elevation={6}
          sx={{
            padding: 4,
            borderRadius: 3,
            textAlign: "center",
            width: "100%",
            maxWidth: 400,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", color: "#333" }}>
            Restablecer Contraseña
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, color: "#666" }}>
            Ingresa el código de verificación que recibiste y tu nueva contraseña.
          </Typography>

          <TextField label="Cédula de Identidad" fullWidth value={cedula} disabled sx={{ mb: 3 }} />

          <TextField
            label="Código de Verificación"
            fullWidth
            value={code}
            onChange={(e) => setCode(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 3 }}
          />

          <TextField
            label="Nueva Contraseña"
            type={showPassword ? "text" : "password"}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon color="primary" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ mb: 3 }}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleResetPassword}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              textTransform: "none",
              py: 1.2,
            }}
          >
            {loading ? "Cambiando..." : "Cambiar Contraseña"}
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default ResetPassword;
