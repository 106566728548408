import React, { useState, useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  Snackbar,
  CircularProgress,
  Typography,
  Paper,
  Box,
  Chip,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import moment from "moment";
import axiosInstance from "../../axiosInstance";
import { SportsSoccer, Schedule, Cancel } from "@mui/icons-material";
import { History } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CourtReservation = () => {
  const [courts, setCourts] = useState([]);
  const [selectedCourt, setSelectedCourt] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const navigate = useNavigate();

  // Estilos reutilizables
  const styles = {
    container: {
      maxWidth: "1200px",
      margin: "2rem auto",
      padding: "2rem",
      borderRadius: "16px",
      boxShadow: "0 8px 32px rgba(0,0,0,0.1)",
      backgroundColor: "#ffffff",
    },
    header: {
      marginBottom: "2rem",
      textAlign: "center",
      color: "#2d3748",
    },
    pickerContainer: {
      display: "flex",
      gap: "2rem",
      marginBottom: "2rem",
      flexDirection: { xs: "column", md: "row" },
    },
    timeSlot: {
      padding: "1rem",
      borderRadius: "8px",
      cursor: "pointer",
      transition: "all 0.3s ease",
      "&:hover": {
        transform: "translateY(-2px)",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
      },
    },
    dialogContent: {
      minWidth: "400px",
      padding: "2rem",
    },
  };
  const isPastTime = (date, time) => {
    const slotDateTime = moment(`${date.format("YYYY-MM-DD")}T${time}`);
    return slotDateTime.isBefore(moment());
  };
  // Cargar canchas al inicio
  useEffect(() => {
    const fetchCourts = async () => {
      try {
        const response = await axiosInstance.get("/courts");
        setCourts(response.data);
        setSelectedCourt(response.data[0]?.id || "");
        setInitialLoad(false);
      } catch (error) {
        setError("Error cargando canchas");
      }
    };
    fetchCourts();
  }, []);

  // Cargar disponibilidad cuando cambia la fecha o la cancha
  useEffect(() => {
    if (selectedCourt) loadAvailableSlots();
  }, [selectedCourt, selectedDate]);

  const loadAvailableSlots = async () => {
    setLoading(true);
    try {
      const params = {
        date: selectedDate.format("YYYY-MM-DD"),
        courtId: selectedCourt,
      };
      const response = await axiosInstance.get("/reservations/available", { params });
      setAvailableSlots(response.data);
    } catch (error) {
      setError("Error cargando disponibilidad");
    } finally {
      setLoading(false);
    }
  };

  const handleSlotSelection = (slot) => {
    if (!slot.available) {
      setError("Este horario no está disponible");
      return;
    }

    // Crear objetos Moment válidos
    const startDate = moment(`${selectedDate.format("YYYY-MM-DD")}T${slot.start}`);
    const endDate = moment(`${selectedDate.format("YYYY-MM-DD")}T${slot.end}`);

    // Verificar que sean fechas válidas
    if (!startDate.isValid() || !endDate.isValid()) {
      setError("Horario inválido");
      return;
    }

    setSelectedSlot({
      start: startDate,
      end: endDate,
    });
    setOpenDialog(true);
  };

  const handleReservation = async () => {
    try {
      // Verificar que selectedSlot y sus propiedades existen
      if (!selectedSlot?.start?.isValid() || !selectedSlot?.end?.isValid()) {
        setError("Selección inválida");
        return;
      }

      const reservationData = {
        courtId: selectedCourt,
        date: selectedDate.format("YYYY-MM-DD"),
        startTime: selectedSlot.start.format("HH:mm:ss"),
        endTime: selectedSlot.end.format("HH:mm:ss"),
      };

      await axiosInstance.post("/reservations", reservationData);
      setOpenDialog(false);
      setConfirmationOpen(true);
      await loadAvailableSlots();
      setError(null);
    } catch (error) {
      setError(error.response?.data?.error || "Error al crear reserva");
    }
  };

  if (initialLoad) return <CircularProgress sx={{ margin: "20% 50%" }} />;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Paper sx={styles.container}>
        <Box sx={styles.header}>
          <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                <SportsSoccer fontSize="large" sx={{ verticalAlign: "middle", mr: 1 }} />
                Reserva de Canchas
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: { md: "right" } }}>
              <Button
                variant="outlined"
                startIcon={<History />}
                onClick={() => navigate("/historialReservas")}
                sx={{
                  mb: 2,
                  borderRadius: "8px",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "primary.light" },
                }}
              >
                Ver Historial de Reservas
              </Button>
            </Grid>
          </Grid>
          <Typography color="text.secondary">
            Selecciona una cancha y fecha para ver los horarios disponibles
          </Typography>
        </Box>

        <Box sx={styles.pickerContainer}>
          <Select
            value={selectedCourt}
            onChange={(e) => setSelectedCourt(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ minWidth: 250 }}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <SportsSoccer />
                {courts.find((c) => c.id === selected)?.name || "Seleccionar cancha"}
              </Box>
            )}
          >
            {courts.map((court) => (
              <MenuItem key={court.id} value={court.id}>
                <SportsSoccer sx={{ mr: 1, color: "text.secondary" }} />
                {court.name} ({court.type})
                <Chip
                  label={court.status}
                  size="small"
                  sx={{ ml: 1 }}
                  color={court.status === "available" ? "success" : "error"}
                />
              </MenuItem>
            ))}
          </Select>

          <DatePicker
            label="Seleccionar fecha"
            value={selectedDate}
            format="DD/MM/YYYY"
            onChange={(newValue) => setSelectedDate(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
            disablePast
          />
        </Box>

        {loading ? (
          <CircularProgress sx={{ display: "block", margin: "2rem auto" }} />
        ) : (
          <Grid container spacing={2}>
            {availableSlots.map((slot, index) => {
              const isPast = isPastTime(selectedDate, slot.end);
              const isDisabled = !slot.available || isPast;

              return (
                <Grid item xs={6} sm={4} md={3} key={index}>
                  <Paper
                    sx={{
                      ...styles.timeSlot,
                      backgroundColor: isDisabled
                        ? "#f5f5f5"
                        : slot.available
                        ? "#f0fdf4"
                        : "#fef2f2",
                      border: `2px solid ${
                        isDisabled ? "#e0e0e0" : slot.available ? "#86efac" : "#fca5a5"
                      }`,
                      color: isDisabled ? "#9e9e9e" : "inherit",
                      pointerEvents: isDisabled ? "none" : "auto",
                    }}
                    onClick={() => handleSlotSelection(slot)}
                  >
                    <Typography
                      variant="subtitle1"
                      color={isDisabled ? "text.disabled" : "text.primary"}
                    >
                      <Schedule
                        sx={{
                          verticalAlign: "middle",
                          mr: 1,
                          color: isDisabled ? "action.disabled" : "inherit",
                        }}
                      />
                      {slot.start} - {slot.end}
                      {isPast && (
                        <Chip
                          label="Finalizado"
                          size="small"
                          sx={{ mt: 1, ml: 1 }}
                          color="default"
                        />
                      )}
                    </Typography>
                    {!isPast && (
                      <Chip
                        label={slot.available ? "Disponible" : "Ocupado"}
                        size="small"
                        color={slot.available ? "success" : "error"}
                        sx={{ mt: 1 }}
                      />
                    )}
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        )}

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle sx={{ bgcolor: "primary.main", color: "white" }}>
            Confirmar Reserva
          </DialogTitle>
          <DialogContent sx={styles.dialogContent}>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <TextField
                  label="Cancha"
                  value={courts.find((c) => c.id === selectedCourt)?.name || ""}
                  fullWidth
                  InputProps={{
                    startAdornment: <SportsSoccer sx={{ mr: 1, color: "action.active" }} />,
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Fecha"
                  value={selectedDate.format("LL")}
                  fullWidth
                  InputProps={{
                    startAdornment: <Schedule sx={{ mr: 1, color: "action.active" }} />,
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Hora Inicio"
                  value={selectedSlot?.start?.format("LT") || ""}
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Hora Fin"
                  value={selectedSlot?.end?.format("LT") || ""}
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={handleReservation}
                  startIcon={<SportsSoccer />}
                >
                  Confirmar Reserva
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ "& .MuiSnackbarContent-root": { borderRadius: "8px" } }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: "1rem",
              backgroundColor: "error.light",
              color: "error.contrastText",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Cancel sx={{ fontSize: "1.5rem" }} />
            <Typography>{error}</Typography>
          </Paper>
        </Snackbar>
        <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
          <DialogTitle sx={{ textAlign: "center" }}>Reserva exitosa</DialogTitle>

          <DialogContent>
            {/* Icono de Check centrado */}
            <Box display="flex" justifyContent="center" mb={2}>
              <CheckCircleIcon sx={{ fontSize: 60, color: "green" }} />
            </Box>

            <DialogContentText sx={{ textAlign: "center" }}>
              La reserva se ha realizado correctamente
            </DialogContentText>
          </DialogContent>

          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={() => setConfirmationOpen(false)} color="primary">
              Aceptar
            </Button>
            {/* <Button onClick={() => navigate(-1)} color="primary" variant="contained">
              Aceptar
            </Button> */}
          </DialogActions>
        </Dialog>
      </Paper>
    </LocalizationProvider>
  );
};

export default CourtReservation;
