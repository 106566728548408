import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Typography,
  Container,
  Grid,
  Box,
  TablePagination,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import esLocale from "date-fns/locale/es";
import axiosInstance from "../../axiosInstance";
import * as XLSX from "xlsx"; // Importación añadida para Excel

const baseUrl = process.env.REACT_APP_BASE_URL;

const RegistroObreroList = () => {
  const [registros, setRegistros] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchCedula, setSearchCedula] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchRegistros();
  }, [page, rowsPerPage]);

  const fetchRegistros = async (startDate = null, endDate = null) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/obreros/getRegistro`, {
        params: {
          startDate: startDate ? startDate.toISOString() : "",
          endDate: endDate ? endDate.toISOString() : "",
        },
      });
      setRegistros(response.data);
    } catch (error) {
      console.error("Error al obtener los registros:", error);
    }
  };

  const handleFilter = () => {
    fetchRegistros(startDate, endDate);
  };

  const handleSearch = (event) => {
    setSearchCedula(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Función añadida para generar Excel
  const handleDownloadExcel = () => {
    const dataForExcel = filteredRegistros.map((registro) => ({
      Nombre: registro.Obrero?.nombre || "N/A",
      Apellido: registro.Obrero?.apellido || "N/A",
      Cédula: registro.Obrero?.cedula_identidad || "N/A",
      Fecha: new Date(registro.createdAt).toLocaleDateString(),
      Hora: new Date(registro.createdAt).toLocaleTimeString(),
      Acción: registro.action,
      Manzana: registro.Obrero?.Lote ? `${registro.Obrero.Lote.manzana}` : "N/A",
      Lote: registro.Obrero?.Lote ? `${registro.Obrero.Lote.lote} ` : "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Registros");
    XLSX.writeFile(workbook, "registros_obreros.xlsx");
  };

  const filteredRegistros = registros.filter((registro) =>
    registro.Obrero?.cedula_identidad.includes(searchCedula)
  );

  const paginatedRegistros = filteredRegistros.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, p: 3, boxShadow: 3, borderRadius: 2, backgroundColor: "white" }}>
        <Typography variant="h5" align="center" gutterBottom>
          Registro de Entradas y Salidas
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={esLocale}>
              <DateTimePicker
                label="Fecha de inicio"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={esLocale}>
              <DateTimePicker
                label="Fecha de término"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button variant="contained" color="primary" fullWidth onClick={handleFilter}>
              Filtrar
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Buscar por cédula"
              variant="outlined"
              value={searchCedula}
              onChange={handleSearch}
              fullWidth
            />
          </Grid>
          {/* Botón añadido para descargar Excel */}
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={handleDownloadExcel}
              sx={{ height: "56px" }} // Para igualar altura con el TextField
            >
              Descargar Excel
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Apellido</TableCell>
                <TableCell>Cédula</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Hora</TableCell>
                <TableCell>Acción</TableCell>
                <TableCell>Lote</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRegistros.map((registro) => (
                <TableRow key={registro.id}>
                  <TableCell>{registro.Obrero?.nombre || "N/A"}</TableCell>
                  <TableCell>{registro.Obrero?.apellido || "N/A"}</TableCell>
                  <TableCell>{registro.Obrero?.cedula_identidad || "N/A"}</TableCell>
                  <TableCell>{new Date(registro.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(registro.createdAt).toLocaleTimeString()}</TableCell>
                  <TableCell>{registro.action}</TableCell>
                  <TableCell>
                    {registro.Obrero?.Lote
                      ? `${registro.Obrero.Lote.lote} - Manzana ${registro.Obrero.Lote.manzana}`
                      : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRegistros.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Container>
  );
};

export default RegistroObreroList;
